<template>
  <!-- 최상단 -->
  <div class="tmenu">
    <div class="wsize">
      <div class="wsize-wrap">
        <div> 
          <ul class="sitelink">
            <div>
              <li v-html="$sanitize(topLinkList[0].content)" @click="openWindow(topLinkList[0].url)"></li>
              <li v-html="$sanitize(topLinkList[1].content)" @click="openWindow(topLinkList[1].url)"></li>
            </div>
            <div class="sitelink-wrap">

            <div :class="{'custom-dropdown': true, open: isDropdownOpen}">
            <a href="#" 
          class="custom-dropdown-btn" 
          @click.prevent="toggleDropdown" 
          >
          <p>소속기관</p>
          <img id="dropdown-icon" src="data:image/gif;base64,R0lGODlhBwALAKIAAFRUVEdHR/b29pCQkISEhAAAAP///wAAACH5BAAAAAAALAAAAAAHAAsAAAMYaKq0XO2VIakJZSpcgFicVX2VQ0COQQgJADs=">
        </a>


            <div v-if="isDropdownOpen" class="custom-dropdown-list" >
              <div v-for="(item, index) in topLinkList.slice(2, 7)" 
                  :key="index + 1" 
                  class="custom-dropdown-item"
                  @click="selectItem(item)"
                  @keydown.enter="selectItem(item)"
                  tabindex="0" role="button">
                <span v-html="$sanitize(item.content)"></span>
              </div>
            </div>
            </div>

            <li v-for="(item, index) in topLinkList.slice(7,13)" :key="index" v-html="$sanitize(item.content)" @click="openWindow(item.url)" ></li>

            </div>
          </ul>
        </div>

      </div>



      
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    },
    topLinkList: {
      type: Array,
      default: ()=> []
    }
  },data() {
    return {
      isDropdownOpen: false, // 드롭다운 열림/닫힘 상태
    };
  },
  methods: {
    toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectItem(item) {
      window.open(item.url, "_blank"); // 선택하면 새 창에서 열기
      this.isDropdownOpen = false; // 선택 후 드롭다운 닫기
    },
    doSiteWClick() {
      this.isShow = !this.isShow
    },
    handleChange(event) {
      const selectedUrl = event.target.value;
      if (selectedUrl) {
        this.openWindow(selectedUrl);
      }
    },
    doLinkClick() {
      this.$emit("onOverlay", 'none');
    },

    openWindow(url){
      if(url == null || url == ''){
        return 
      }
      if(url.indexOf(window.location.origin) == 0 || url.indexOf('/') == 0){
        window.location.href = url
      }else{
        window.open(url)
      }
    },
  }
}
</script>
