<template>
  <div class="myContent">
    <div id="foot">
      <div class="wsize">
        <div class="flogo">
          <img src="@/assets/img/builder/logo_eng_footer_resize.png" alt="국립백두대간수목원">
        </div>
        <div class="finfo">
          <p><span>36209) Baekdudaegan National Arboretum at 1501, Chunyang-ro, Chunyang-myeon, Bonghwa-gun, Gyeongsangbuk-do <span class="mobile_no">/</span><br class="web_no"> +82-54-679-1000</span><br>
          Corporate registration number : <span>835-82-00095</span> / President : Lee Gyu-Myoung</p>
          <p><span>@ Baekdudaegan National Arboretum. All Rights Reserved.</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
}
</script>