<template>
  <div class="myContent">
    <div id="foot">
      <div class="fmenu">
        <div class="fmenu_cont">
          <ul class="fmenu_fl">
            <li>
              <router-link :to="{ name: 'content', query: {menuId: 'M0015', upMenuId:'M0005', s0:null}}" :tabindex="tabindex">{{$t('termsUse')}}</router-link>
            </li>
            <li>
              <a href="https://www.koagi.or.kr/contents/dynamicDetail?menuId=M0003&upMenuId=M0001&s0&postNo=-1" target="_blank" :tabindex="tabindex+1">{{$t('privacy')}}</a>
            </li>
            <li>
              <router-link :to="{ name: 'content', query: {menuId: 'M0017', upMenuId:'M0005', s0:null}}" :tabindex="tabindex+2">{{$t('copyPolicy')}}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'dynamicDetail', query: {menuId: 'M0018', upMenuId:'M0005', s0:null, postNo: '-1'}}" :tabindex="tabindex+3">{{$t('videoSystem')}}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'content', query: {menuId: 'M0019', upMenuId:'M0005', s0:null}}" :tabindex="tabindex+4">{{$t('customService')}}</router-link>
            </li>
            <li>
              <a href="https://www.koagi.or.kr/contents/content?menuId=M0027&upMenuId=M0025&s0=2784#cont_title" target="_blank" :tabindex="tabindex+5">{{$t('ethicsManage')}}</a>
            </li>
          </ul>
          <div class="farea">
            <!-- sns 바로가기 -->
            <ul class="fsns">
              <li>
                <a href="https://www.youtube.com/@koagi" target="_blank" :tabindex="tabindex+6">
                <img src="@/assets/img/sample01/img/common/foot_sns1.png" :alt="$t('goBdYoutube')"></a>
              </li>
              <li>
                <a href="https://www.instagram.com/koagi_platform" target="_blank">
                  <img src="@/assets/img/sample01/img/common/foot_sns2.png" :alt="$t('goBdInsta')" :tabindex="tabindex+7">
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/koagiplatform" target="_blank">
                  <img src="@/assets/img/sample01/img/common/foot_sns4.png" :alt="$t('goBdFacebook')" :tabindex="tabindex+8">
                </a>
              </li>
              <li>
                <a href="https://blog.naver.com/koagi1" target="_blank">
                  <img src="@/assets/img/sample01/img/common/foot_sns3.png" :alt="$t('goBdBlog')" :tabindex="tabindex+9">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="wsize">                                                                                                                                                                                        
        <div class="flogo">
          <img src="@/assets/img/sample01/img/common/f_logo.png" :alt="$t('nationalBdna')">
        </div>
        <div class="finfo">
          <p>
            <span>36209</span> 경상북도 봉화군 춘양면 춘양로 <span>1501</span>
            <br class="web_no">국립백두대간수목원<br class="web_no">
            <span class="mobile_no">/</span>
            <span>054-679-1000</span><br>
            사업자번호 : <span>835-82-00095</span> / 대표자명 : 이규명
          </p>
          <p>
            <span>@</span> 국립백두대간수목원. 
            <span>All Rights Reserved.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
}
</script>